import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PublicFormTitle } from '../../models/ClientPublicForm';
import { FormConfig } from '../../models/Form';
import ClientPublicFormService from '../../services/ClientPublicFormService';
import PublicFormFinished from '../../components/public-forms/PublicFormFinished';
import PublicFormHeader from '../../components/public-forms/PublicFormHeader';
import PublicFormNotFound from '../../components/public-forms/PublicFormNotFound';
import PublicFormView from '../../components/public-forms/PublicFormView';
import PublicFormWelcome from '../../components/public-forms/PublicFormWelcome';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { EventSystem } from '../../events/EventSystem';
import { QuestionVisibilityChanged } from '../../events/QuestionVisibilityChanged';
import FormAnswerEvent, { AdHocFormAnswerEvent } from '../../events/FormAnswerEvent';
import { QuestionRequirednessChanged } from '../../events/QuestionRequirednessChanged';
import LanguageUtils from '../../utils/LanguageUtils';
import ContentWrapper from '../../components/public-forms/ContentWrapper';
import { SupportedLanguage, systemDefaultLanguageCode } from '../../types/Languages';

const PublicFormPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [formInfo, setFormInfo] = useState<PublicFormTitle | null>(null);
  const [form, setForm] = useState<FormConfig | null>(null);
  const [requestCode, setRequestCode] = useState<string | null>(null);
  const [stepNum, setStepNum] = useState(0);
  const [defaultLanguage, setDefaultLanguage] = useState<SupportedLanguage>(systemDefaultLanguageCode);
  const { publicFormId } = useParams<{ publicFormId: string }>();

  const goNext = useCallback(() => {
    setStepNum((prev) => prev + 1);
  }, []);

  const steps: ReactElement[] = useMemo(
    () => [
      <PublicFormWelcome key={0} goNext={goNext} setForm={setForm} defaultLanguage={defaultLanguage} />,
      <PublicFormView key={1} form={form} goNext={goNext} setRequestCode={setRequestCode} />,
      <PublicFormFinished key={2} requestCode={requestCode} />,
    ],
    [form, goNext, requestCode, defaultLanguage],
  );

  useEffect(() => {
    setLoading(true);

    ClientPublicFormService.getTitle(publicFormId as string)
      .then((res) => {
        setFormInfo(res.data);
        setDefaultLanguage(res.data.defaultLanguage as SupportedLanguage);
        setStepNum(0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [publicFormId]);

  useEffect(function onBeforeAnswer() {
    const handler = (event: FormAnswerEvent) => {
      setForm(
        (prev) =>
          prev && {
            ...prev,
            answers: { ...prev.answers, [event.actionId]: event.answer },
          },
      );
    };

    EventSystem.listen('before-form-answer', handler);

    return () => EventSystem.stopListening('before-form-answer', handler);
  }, []);

  useEffect(function onBeforeAdHocAnswer() {
    const handler = (event: AdHocFormAnswerEvent) => {
      setForm(
        (prev) =>
          prev && {
            ...prev,
            adHocAnswers: { ...prev.adHocAnswers, [event.actionId]: [...(prev.adHocAnswers[event.actionId] ?? []), event.adHocAnswer] },
          },
      );
    };

    EventSystem.listen('before-ad-hoc-form-answer', handler);
    return () => EventSystem.stopListening('before-ad-hoc-form-answer', handler);
  }, []);

  useEffect(function onQuestionVisibilityChanged() {
    const handler = (event: QuestionVisibilityChanged) => {
      setForm(
        (prev) =>
          prev && {
            ...prev,
            sections: prev.sections.map((step) => ({
              ...step,
              actions: step.actions.map((action) => (event.actionId === action.id ? event.action : action)),
            })),
          },
      );
    };

    EventSystem.listen('question-visibility-changed', handler);

    return () => EventSystem.stopListening('question-visibility-changed', handler);
  }, []);

  useEffect(function onQuestionRequirednessChanged() {
    const handler = (event: QuestionRequirednessChanged) => {
      setForm(
        (prev) =>
          prev && {
            ...prev,
            sections: prev.sections.map((step) => ({
              ...step,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions: step.actions.map((action) => (event.actionId === action.id ? { ...action, required: event.isNowRequired as any } : action)),
            })),
          },
      );
    };

    EventSystem.listen('question-requiredness-changed', handler);

    return () => EventSystem.stopListening('question-requiredness-changed', handler);
  }, []);

  return (
    <div className="min-h-full">
      <PageLoader loading={loading}>
        {!formInfo ? (
          <PublicFormNotFound />
        ) : (
          <ContentWrapper>
            <PublicFormHeader
              title={
                LanguageUtils.getTranslation('publicTitle', formInfo.translations) || LanguageUtils.getTranslation('title', formInfo.translations)
              }
              description={LanguageUtils.getTranslation('publicDescription', formInfo.translations)}
            />{' '}
            {steps[stepNum]}
          </ContentWrapper>
        )}
      </PageLoader>
    </div>
  );
};

export default PublicFormPage;
