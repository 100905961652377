import { FC, useCallback, useEffect, useRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';

type FriendlyCaptchaProps = {
  onChange: (solution: string | null) => void;
  onError: (err: string) => void;
  language?: string;
};

const FriendlyCaptcha: FC<FriendlyCaptchaProps> = (props) => {
  const { onChange, onError, language } = props;
  const container = useRef<HTMLDivElement | null>(null);
  const widget = useRef<WidgetInstance | null>(null);

  const doneCallback = useCallback(
    (solution: string) => {
      onChange(solution);
    },
    [onChange],
  );

  const errorCallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err: any) => {
      onError(err);
    },
    [onError],
  );

  useEffect(() => {
    // Timeout & destroy below, to stop race condition where it would initialize twice
    setTimeout(() => {
      if (!widget.current) {
        widget.current = new WidgetInstance(container.current!, {
          startMode: 'auto',
          doneCallback: doneCallback,
          errorCallback: errorCallback,
        });
      }
    }, 10);

    return () => {
      if (widget.current) {
        widget.current.destroy();
        widget.current = null;
      }
    };
  }, [doneCallback, errorCallback]);

  return (
    <div
      ref={container}
      className="my2 border-gray-5 border p-2"
      data-sitekey={(import.meta.env.VITE_FRIENDLYCAPTCHA_KEY as string) ?? ''}
      data-lang={language}
    />
  );
};

export default FriendlyCaptcha;
