import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../shared/text/Heading';
import PublicFormBox from './PublicFormBox';

type PublicFormFinishedProps = {
  requestCode: string | null;
};

const PublicFormFinished: FC<PublicFormFinishedProps> = (props) => {
  const { requestCode } = props;
  const { t } = useTranslation('public-forms');

  return (
    <PublicFormBox>
      <div className="text-center">
        <Heading size={HeadingSize.H3}>{t('end.title')}</Heading>
        <div className="my-4">{t('end.description')}</div>
        <Heading size={HeadingSize.H4} textColor="text-gray-1">
          {requestCode}
        </Heading>
      </div>
    </PublicFormBox>
  );
};

export default PublicFormFinished;
