import i18next from 'i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormConfig } from '../../models/Form';
import BaseService from '../../services/BaseService';
import ClientPublicFormService from '../../services/ClientPublicFormService';
import { supportedLanguages } from '../../types/Languages';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import PublicFormBox from './PublicFormBox';
import PublicFormFooter from './PublicFormFooter';
import FriendlyCaptcha from '../shared/FriendlyCaptcha';

type PublicFormWelcomeProps = {
  setForm: (form: FormConfig) => void;
  goNext: () => void;
  defaultLanguage: string;
};

const PublicFormWelcome: FC<PublicFormWelcomeProps> = (props) => {
  const { setForm, goNext, defaultLanguage } = props;
  const [nextDisabled, setNextDisabled] = useState(true);
  const [friendlyCaptchaFailed, setFriendlyCaptchaFailed] = useState(false);
  const { t } = useTranslation(['public-forms', 'common']);
  const { publicFormId } = useParams<{ publicFormId: string }>();

  const langauges = useMemo(
    () =>
      supportedLanguages.map((lang) => ({
        id: lang.id,
        text: t(`common:languages.${lang.id}`),
        value: lang.value,
      })),
    [t],
  );

  const selectedLangauge = useMemo(() => {
    return langauges.find((x) => x.id === i18next.language);
  }, [langauges]);

  const onLanguageChange = useCallback((lang: string) => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    i18next.changeLanguage(defaultLanguage);
  }, [defaultLanguage]);

  const onFriendlyCaptchaDone = useCallback(
    (solution: string | null) => {
      setNextDisabled(true);

      if (solution) {
        BaseService.disableInterceptorErrors = true;
        ClientPublicFormService.authorize(publicFormId as string, solution, (import.meta.env.VITE_FRIENDLYCAPTCHA_KEY as string) ?? '')
          .then((res) => {
            setForm(res.data.templateForm);
            setNextDisabled(false);
            setFriendlyCaptchaFailed(false);
          })
          .catch(() => {
            setFriendlyCaptchaFailed(true);
          })
          .finally(() => {
            BaseService.disableInterceptorErrors = false;
          });
      } else {
        setFriendlyCaptchaFailed(true);
      }
    },
    [publicFormId, setForm],
  );

  const onFriendlyCaptchaError = useCallback(() => {
    setFriendlyCaptchaFailed(true);
  }, []);

  return (
    <>
      <PublicFormBox>
        <div className="flex items-center justify-between">
          <label htmlFor="language-selector" className="text-dpm-14 font-medium text-black">
            {t('public-forms:welcome.select-language')}
          </label>
          <DropdownSelect
            id="language-selector"
            wrapperClassName="w-42"
            options={langauges}
            onChange={(o) => onLanguageChange(o.id)}
            value={selectedLangauge}
          />
        </div>
        <div className="py-4">
          <FriendlyCaptcha onChange={onFriendlyCaptchaDone} onError={onFriendlyCaptchaError} language={i18next.language} key={i18next.language} />
          {friendlyCaptchaFailed && <div className="text-semantic-2">{t('public-forms:recaptcha.failed')}</div>}
        </div>
      </PublicFormBox>
      <PublicFormFooter onNext={goNext} disabled={nextDisabled} buttonText={t('public-forms:footer.next')} />
    </>
  );
};

export default PublicFormWelcome;
